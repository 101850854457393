<template>
  <div class="home-new">
    <BaseLeftTitle title="新闻资讯" :hava-slot="true" sub-title="公司最新动态">
      <div ref="target" style="position: relative;height: 360px;">
        <!-- 面板内容 -->
        <Transition name="fade">
          <ul class="home-news-list">
            <li v-for="(news,index) in newsList" :key="index">
              <NuxtLink :to="`/news/detail/${news.newsId}`">
                <img :src="news.newsImage" :alt="news.newsName">
                <p class="name ellipsis">{{ news.newsName }}</p>
                <p class="attr ellipsis-2" style="text-align: left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    news.newsDesc
                  }}</p>
                <p class="time ellipsis-3">{{ news.createTime }}</p>
                <!-- <p class="price">&yen;</p> -->
              </NuxtLink>
            </li>
          </ul>
        </Transition>
      </div>
    </BaseLeftTitle>
  </div>
</template>
<script setup>
import { findNewsByCategoryId } from '../../composables/api/news'

const newsList = ref([])
const reqParams = reactive({
  pageIndex: 1,
  pageSize: 3,
})

onMounted(async () => {
  try {
    const data = await findNewsByCategoryId(reqParams);
    if (data && data.data && data.data.records) {
      newsList.value = data.data.records;
    } else {
      console.error('新闻列表数据格式异常:', data);
    }
  } catch (error) {
    console.error('获取新闻列表失败:', error);
  }
})
</script>
<style scoped lang="less">
.home-new {
  height: 60vh;

  .home-news-list {
    display: flex;
    justify-content: space-between;
    height: 40vh;

    li {
      width: 32%;
      min-height: 15vh;
      background: #fff;
      justify-content: center;
      align-items: center;

      a {
        display: flex;
        flex-direction: column;
        height: 100%;
        .hoverShadow();
      }

      img {
        width: 100%;
        height: 27vh;
      }

      p {
        line-height: 2vh;
        font-size: 2vh;
        text-align: center;
        padding-top: 1vh;

        &.attr {
          color: #999;
          font-size: 1.5vh;
          height: 9vh;
          flex: 1;

          span {
            margin-right: 5px;
            padding-left: 5px;
          }
        }

        &.time {
          color: #999;
          font-size: 2vh;
          text-align: right;
          padding-right: 10px;
          padding-bottom: 10px;
        }

        .price {
          color: @priceColor;
        }
      }
    }
  }

  :after {
    content: '';
    display: block;
    clear: both;
  }
}
</style>
