<template>
  <div id="home-index">
    <!-- 首页入口 -->
    <HomeTop/>
    <!-- 产品 -->
    <HomeProduct/>
    <!-- 解决方案 -->
    <HomeAdvice/>
    <!--  关于我们   -->
    <CompanyAboutUs/>
    <!--  公司进程   -->
    <CompanyDevelopInfo/>
    <!--  技术资源    -->
    <CompanyTechnologyResources/>
    <!--  服务客户    -->
    <CompanyServiceCustomer/>
    <!--  企业荣誉   -->
    <HonorCompanyHonor :swiper-rows="1" swiper-height="350px"/>
    <!--  知识产权   -->
    <HonorCompanyProperty :swiper-rows="1" :swiper-row-views="4" swiper-height="350px"/>
    <!-- 新闻资讯 -->
    <HomeNew/>
  </div>
</template>

<script setup>

// Nuxt 3 的 useMeta 设置页面元数据

useHead({
  title: '杭州建航物流科技官网',
  meta: [
    {name: 'keywords', content: '立体仓库、建航物流科技、仓储软件、提升输送机'},
    {
      name: 'description',
      content: '杭州建航物流科技有限公司是全面物流解决方案的提供者。为各个不同行业服务，在供应链的不同环节，通过向客户提供国际先进的物流管理技术、经验和高品质的物流相关系统，通过持续的物流改善，协助客户在降低其物流总成本条件下实现既定的客户服务水平，提高企业物流能力和市场核心竞争能力。'
    }
  ]
})
</script>

<style scoped lang="less">
#home-index {
  width: 100%;
}
</style>
